html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font-size: 18px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
}
h1 {
  font-size: 48px;
  line-height: 60px;
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
}
@media (max-width: 480px) {
  h1 {
    font-size: 2em;
    line-height: 1.5em;
  }
}
h2 {
  font-size: 34px;
  line-height: 42px;
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
}
h3 {
  font-size: 24px;
  line-height: 32px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
}
h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
}
@media (max-width: 480px) {
  h4 {
    font-size: 1em;
    line-height: 1.5em;
  }
}
h5 {
  font-size: 18px;
  line-height: 24px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
}
h6 {
  font-size: 14px;
  line-height: 20px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
}
p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 300;
}
.paragraph__large {
  font-size: 20px;
  line-height: 28px;
}
small {
  font-size: 14px;
  line-height: 20px;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 300;
}
.allCaps {
  text-transform: uppercase;
}
.brandBlue {
  color: #1b5291;
}
.text-center {
  text-align: center;
}
strong {
  font-weight: 800;
}
.hours-info {
  line-height: 24px;
}
a {
  display: inline-block;
  color: #1b5291;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}
a:hover {
  color: #236abc;
  text-decoration: underline;
}
.anchor-link {
  color: #1b5291;
  cursor: pointer;
}
.anchor-link:hover {
  color: #236abc;
  text-decoration: underline;
}
.contact-link {
  display: inline-flex;
  align-items: center;
  margin-right: 35px;
}
@media (max-width: 480px) {
  .contact-link {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
.contact-link > svg {
  margin-right: 8px;
}
html,
body {
  width: 100%;
  min-height: 100%;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-size: 16px;
  background: #fff;
  color: #1e1d1e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  background: transparent;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#root,
#root > div {
  min-height: 100vh;
}
hr {
  width: 100%;
  height: 1px;
  background: #bcbcc0;
  border: none;
  margin: 40px auto;
}
img {
  display: inline-block;
  max-width: 100%;
}
.margin-bottom__xs {
  margin-bottom: 8px !important;
}
.margin-bottom__s {
  margin-bottom: 16px !important;
}
.margin-bottom__m {
  margin-bottom: 24px !important;
}
.margin-bottom__l {
  margin-bottom: 35px !important;
}
@media (max-width: 480px) {
  .margin-bottom__l {
    margin-bottom: 16px !important;
  }
}
.margin-bottom__xl {
  margin-bottom: 40px !important;
}
.margin-top__xs {
  margin-top: 8px !important;
}
.margin-top__s {
  margin-top: 16px !important;
}
.margin-top__m {
  margin-top: 24px !important;
}
.margin-top__l {
  margin-top: 35px !important;
}
.margin-top__xl {
  margin-top: 40px !important;
}
.body-lock {
  overflow: hidden;
}
.section-divider {
  margin: 24px 0;
  background: #7ea9db;
}
.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.banner {
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.banner > svg {
  margin-right: 8px;
}
.banner.info {
  background: rgba(240,241,247,0.25);
  color: #1e1d1e;
  font-size: 14px;
}
@media (max-width: 480px) {
  .main-layout.hasMenuOpen {
    position: fixed;
    overflow: hidden;
  }
}
.main-layout .main-layout__wrapper {
  padding-top: 129px;
}
@media (max-width: 480px) {
  .main-layout .main-layout__wrapper {
    padding-top: 0;
  }
}
.main-layout .main-layout__container {
  padding: 48px;
  min-height: calc(100vh - 129px - 60px);
  display: flex;
  justify-content: center;
}
@media (max-width: 480px) {
  .main-layout .main-layout__container {
    padding: 35px;
  }
}
.main-layout .footer {
  background-color: #1b5291;
  display: flex;
  justify-content: center;
  padding: 24px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.contact-page {
  max-width: 1370px;
  flex: 1 1 100%;
}
.contact-page .content__navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .contact-page .content__navigation {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .contact-page .content__navigation .recipient-id {
    margin-top: 24px;
  }
}
.layout__columns-2 {
  display: flex;
  justify-content: space-between;
}
.layout__columns-2 .layout__column {
  flex: 1;
}
.layout__columns-2 .layout__column-left {
  flex: 1;
  margin-right: 48px;
}
.layout__columns-2 .layout__column-right {
  flex: 1;
}
.header {
  background-color: #1b5291;
  color: #fff;
  padding: 24px 48px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
@media (max-width: 480px) {
  .header {
    padding: 8px 24px;
    position: relative;
  }
  .header.hasMenuOpen {
    height: 100vh;
    overflow: hidden;
  }
}
.header .header__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .header .header__wrapper {
    flex-direction: column;
  }
}
.header .header__wrapper > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}
.header .header__branding > a > svg {
  width: 100%;
}
@media (max-width: 480px) {
  .header .header__branding > a > svg {
    width: 140px;
  }
}
.header .mobile__navigation {
  padding: 35px 0;
}
.header .mobile__navigation ul {
  margin-top: 16px;
}
.header .mobile__navigation ul li {
  color: #fff;
  font-size: 24px;
  display: flex;
  flex: 1;
}
.header .mobile__navigation ul li.menu__list {
  display: flex;
  flex-direction: column;
}
.header .mobile__navigation ul li.menu__list .menu__header {
  color: #fff;
  margin: 16px 0;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  opacity: 0.7;
}
.header .mobile__navigation ul li.menu__list ul {
  margin: 0;
}
.header .mobile__navigation ul li.menu__list ul a {
  color: #fff;
  margin-left: 8px;
}
.header .mobile__navigation ul li > a {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 16px 8px;
  flex: 1;
  font-size: 24px;
  opacity: 0.7;
}
.header .mobile__navigation ul li > a.button {
  padding: 14px 35px;
}
.header .mobile__navigation ul li > a.active,
.header .mobile__navigation ul li > a:hover {
  opacity: 1;
  text-decoration: none;
}
.header .mobile__navigation ul li > a.active {
  font-weight: 800;
}
.header .mobile__navigation ul li > a > span > svg {
  margin-left: 8px;
}
.header .mobile__navigation ul li > a .count-bubble {
  background: #fff;
  border: 2px solid #1b5291;
  border-radius: 50px;
  font-weight: 800;
  color: #1b5291;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  right: -18px;
  top: -8px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
}
.header .mobile__navigation ul li > a .count-bubble.max {
  right: -26px;
  top: -10px;
  width: 36px;
  height: 24px;
}
.header .header__navigation {
  display: flex;
  align-items: center;
}
.header .header__navigation ul {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.header .header__navigation ul li {
  margin-left: 16px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
.header .header__navigation ul li .link__withDropdown {
  padding: 16px 8px;
}
.header .header__navigation ul li .link__withDropdown > span {
  opacity: 0.7;
}
.header .header__navigation ul li .link__withDropdown > span:hover {
  opacity: 1;
}
.header .header__navigation ul li .navigation__dropdown {
  position: absolute;
  top: 24px;
  margin-top: 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(148,122,178,0.3);
  display: flex;
  flex-direction: column;
  min-width: 180px;
}
.header .header__navigation ul li .navigation__dropdown > a {
  color: #1b5291;
  font-size: 16px;
  display: block;
  padding: 16px;
}
.header .header__navigation ul li .navigation__dropdown > a:hover {
  background: rgba(126,169,219,0.25);
  text-decoration: none;
}
.header .header__navigation ul li > a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  display: flex;
  padding: 16px 8px;
  opacity: 0.7;
}
.header .header__navigation ul li > a.button {
  padding: 14px 35px;
}
.header .header__navigation ul li > a.active,
.header .header__navigation ul li > a:hover {
  opacity: 1;
  text-decoration: none;
}
.header .header__navigation ul li > a.active {
  font-weight: 800;
}
.header .header__navigation ul li > a > span > svg {
  margin-left: 8px;
}
.header .header__navigation ul li > a .count-bubble {
  background: #fff;
  border: 2px solid #1b5291;
  border-radius: 50px;
  font-weight: 800;
  color: #1b5291;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  right: -18px;
  top: -8px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
}
.header .header__navigation ul li > a .count-bubble.max {
  right: -26px;
  top: -10px;
  width: 36px;
  height: 24px;
}
.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 768px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.paper {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(148,122,178,0.35);
}
@media (min-width: 768px) {
  .paper {
    padding: 24px;
  }
}
@media (min-width: 1024px) {
  .paper {
    padding: 35px;
  }
}
.auth-layout {
  display: flex;
  height: 100vh;
}
.auth-layout .auth-layout__left {
  background-color: #1b5291;
  background-image: url("../src/common/images/donor-background.png");
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  width: 40%;
}
@media screen and (max-width: 1024px) {
  .auth-layout .auth-layout__left {
    display: none;
  }
}
.auth-layout .auth-layout__right {
  padding: 40px 120px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .auth-layout .auth-layout__right {
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .auth-layout .auth-layout__right {
    padding: 35px;
  }
}
.auth-layout .auth-layout__form-container {
  max-width: 435px;
}
@media screen and (max-width: 1024px) {
  .auth-layout .auth-layout__form-container {
    width: 100%;
  }
}
.auth-layout .auth-layout__left__testimonials {
  max-width: 500px;
}
.auth-layout .auth-layout__left__testimonials .auth-layout__left__testimonials__stars {
  margin-bottom: 16px;
}
.auth-layout .auth-layout__left__testimonials .auth-layout__left__testimonials__stars > svg {
  margin-right: 4px;
}
.auth-layout .auth-layout__left__testimonials .auth-layout__left__testimonials__stars > svg:last-child {
  margin-right: 0;
}
.auth-layout .auth-layout__left__testimonials .auth-layout__left__testimonials__quote {
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 32px;
}
.auth-layout .auth-layout__left__testimonials .auth-layout__left__testimonials__author {
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin-top: 16px;
}
.auth-layout .auth-layout__footer {
  font-size: 14px;
  margin-top: 16px;
}
.error-layout {
  background: #1b5291;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  flex: 1;
}
.error-layout .error-layout__content {
  background: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(148,122,178,0.35);
}
.error-layout__notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logo {
  width: 200px !important;
  height: 200px !important;
  fill: $primary !important;
}
.container > * {
  margin: 32px auto;
}
.styleguide__typography > * + * {
  margin-top: 32px;
}
.styleguide__title {
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 600;
}
.styleguide__row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.styleguide__row:last-child {
  margin-bottom: 0;
}
.styleguide__row > * {
  margin-right: 16px;
  margin-top: 16px;
}
.styleguide__row > *:last-child {
  margin-right: 0;
}
.loading-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
}
.loading-padded {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  flex-direction: column;
}
.button {
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
  font-size: 16px;
  display: inline-block;
  background-image: transparent;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  padding: 18px 35px;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  transition: border-color 150ms ease, background-color 150ms ease, color 150ms ease;
  user-select: none;
}
.button--has-icon {
  display: flex;
  align-items: center;
}
.button--has-icon > svg {
  margin-right: 8px;
}
.button--has-icon.button--icon-right > svg {
  margin-left: 8px;
}
.button--primary {
  background-image: linear-gradient(180deg, #1b5291 0%, #093762 100%);
  color: #fff;
}
.button--primary:hover {
  border-color: #3f1a41;
  box-shadow: 0 0 5px 0 rgba(101,35,101,0.5);
}
.button--primary-alt {
  background: #fff;
  border: 2px solid #1b5291;
  color: #1b5291;
  font-weight: 800;
}
.button--primary-alt:hover {
  background-image: linear-gradient(180deg, #1b5291 0%, #093762 100%);
  color: #fff;
}
.button--primary-alt:hover > svg,
.button--primary-alt:hover > div > svg {
  fill: #fff;
}
.button--primary-alt:hover > svg > path,
.button--primary-alt:hover > div > svg > path {
  fill: #fff;
}
.button--secondary {
  background-image: linear-gradient(180deg, #1b5291 0%, #093762 100%);
  border-color: #1b5291;
  color: #fff;
}
.button--secondary:hover {
  background-color: #093762;
  border-color: #093762;
  box-shadow: 0 0 5px 0 rgba(101,35,101,0.5);
}
.button--white {
  background: #fff;
  border: none;
  color: #1b5291;
  font-weight: 800;
}
.button--white:hover {
  background: #1b5291;
  color: #fff;
}
.button--white:hover > svg,
.button--white:hover > div > svg {
  fill: #fff;
}
.button--white:hover > svg > path,
.button--white:hover > div > svg > path {
  fill: #fff;
}
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.button--text {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  outline: 0;
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
}
.button--text > div a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button--text > div a > svg {
  margin-right: 4px;
}
.button--text:hover {
  text-decoration: underline;
}
.button--text.button--text__small {
  font-size: 14px;
}
.button--icon {
  cursor: pointer;
  padding: 16px;
}
.type__primary {
  color: #1b5291;
}
.button--size--small {
  padding: 6px 16px;
  font-size: 14px;
}
.button--size--medium {
  padding: 12px 16px;
  font-size: 14px;
}
.button--size--large {
  min-width: 180px;
  padding: 18px;
  font-size: 18px;
}
.button--full-width {
  display: block;
  width: 100%;
}
.button__children {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-group-row {
  display: flex;
}
.button-group-row > button {
  flex: 1;
}
.button-group > button {
  margin-right: 8px;
}
@media (max-width: 1223px) {
  .button-group > button {
    margin-bottom: 8px;
  }
}
.button-group > button:last-of-type {
  margin-right: 0;
}
.tab-filters {
  border-bottom: 1px solid #7ea9db;
}
.tab-filters ul {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .tab-filters ul {
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .tab-filters ul li {
    display: flex;
    width: 100%;
  }
}
.tab-filters ul li a {
  color: #1e1d1e;
  opacity: 0.5;
  font-size: 18px;
  font-weight: 800;
  padding: 16px;
  border-bottom: 4px solid transparent;
}
@media (max-width: 480px) {
  .tab-filters ul li a {
    border: 2px solid #bcbcc0;
    border-radius: 12px;
    margin-bottom: 8px;
    flex: 1;
    text-align: center;
  }
}
.tab-filters ul li a:hover {
  text-decoration: none;
  opacity: 1;
}
.tab-filters ul li a.active {
  opacity: 1;
  font-weight: 800;
  border-bottom: 4px solid #1b5291;
}
@media (max-width: 480px) {
  .tab-filters ul li a.active {
    color: #fff;
    background: #1b5291;
    border: 2px solid #1b5291;
  }
}
.tab-filters ul li.view-toggles {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}
@media (max-width: 480px) {
  .tab-filters ul li.view-toggles {
    margin-top: 16px;
    justify-content: center;
  }
}
.tab-filters ul li.view-toggles > a {
  padding: 4px;
  border: none;
  opacity: 1;
}
.tab-filters ul li.view-toggles > svg {
  margin-right: 8px;
}
.tab-filters ul li.view-toggles > svg:last-child {
  margin-right: 0;
}
.donor-card {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(148,122,178,0.35);
  cursor: pointer;
  height: 420px;
  padding: 16px 16px 24px 16px;
  position: relative;
  transition: 0.3s all ease-in-out;
  background-position: center top;
/* Gradient Overlay */
/* Card Hover */
/* Inactive Card */
/* Card Wrapper */
/* Card Header */
/* Card Status*/
/* Card Details */
/* Card Bottom */
/* Donor ID */
}
@media (max-width: 480px) {
  .donor-card {
    height: 420px;
  }
}
.donor-card:before {
  border-radius: 12px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(27,82,145,0.9) 0%, rgba(27,82,145,0) 50%);
  transition: 0.3s all ease-in;
}
.donor-card:hover {
  transform: scale(1.02);
}
.donor-card:hover .donor-card__donor-details {
  opacity: 1;
}
.donor-card:hover:before {
  background: linear-gradient(to top, rgba(27,82,145,0.9) 0%, rgba(27,82,145,0.9) 100%);
  opacity: 0.95;
}
.donor-card.donor-card--inactive {
  box-shadow: none;
}
.donor-card.donor-card--inactive:before {
  background: #bcbcc0;
  opacity: 0.75;
}
.donor-card.donor-card--inactive:hover:before {
  background: #87878f;
  opacity: 0.8;
}
.donor-card.donor-card--inactive:hover .donor-card__donor-details {
  opacity: 0;
}
.donor-card .donor-card__container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.donor-card .donor-card__top {
  display: flex;
  justify-content: space-between;
}
.donor-card .donor-card__top .donor-favorite {
  margin-left: auto;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid rgba(197,198,224,0.6);
}
.donor-card .donor-card__top .donor-favorite:hover {
  cursor: pointer;
  border-color: #f46081;
}
.donor-card .donor-status {
  align-items: center;
  border-radius: 50px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 800;
  padding: 3px 10px;
  text-transform: uppercase;
}
.donor-card .donor-status.coming-soon {
  background: #d1a053;
}
.donor-card .donor-status.inactive {
  background: #bcbcc0;
  color: #1e1d1e;
}
.donor-card .donor-card__donor-details {
  color: #fff;
  padding-top: 16px;
  opacity: 0;
  height: 100%;
  transition: 0.3s all ease-in-out;
}
.donor-card .donor-card__donor-details .donor-card__donor-details__row {
  display: flex;
  margin-bottom: 16px;
}
.donor-card .donor-card__donor-details .donor-card__donor-details__detail {
  flex: 1;
  margin-right: 8px;
  line-height: 16px;
}
.donor-card .donor-card__donor-details .donor-card__donor-details__detail .value {
  font-weight: 800;
  margin-bottom: 6px;
  font-size: 14px;
}
.donor-card .donor-card__donor-details .donor-card__donor-details__detail .label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
}
.donor-card .donor-card__bottom {
  display: flex;
  margin-bottom: 8px;
/* Donor Bundle */
/* Donor View Button */
}
.donor-card .donor-card__bottom .donor-card__charm {
  background: #fff;
  border-radius: 50%;
  color: #1b5291;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}
.donor-card .donor-card__bottom .donor-card__charm:last-of-type {
  margin-right: 0;
}
.donor-card .donor-card__bottom .donor-card__view-button {
  margin-left: auto;
}
.donor-card .donor-card__donor-id {
  bottom: -42px;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.donor-card .donor-card__donor-id span {
  padding: 18px 35px;
  background-image: linear-gradient(180deg, #d1a053 0%, #b78738 100%);
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  padding: 0 24px;
  height: 36px;
  line-height: 36px;
}
.donor-card .donor-card__donor-id span:hover {
  background: #b78738;
}
.donor-list-item {
  background: #fff;
  border: 1px solid rgba(197,198,224,0.6);
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(30,29,30,0.15);
  display: flex;
  align-items: center;
  padding: 16px 24px;
  transition: 0.3s all ease-in-out;
/* Card Status*/
}
@media (max-width: 480px) {
  .donor-list-item {
    margin-bottom: 16px;
    padding: 16px;
    flex-direction: column;
  }
}
.donor-list-item:hover {
  border: 2px solid #1b5291;
  transform: scale(1.02);
}
.donor-list-item .donor-list-item__profile-img {
  flex-shrink: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__profile-img {
    margin-bottom: 16px;
  }
}
.donor-list-item .donor-list-item__profile-img div {
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}
.donor-list-item .donor-status {
  align-items: center;
  border-radius: 50px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 800;
  padding: 3px 10px;
  text-transform: uppercase;
  margin-top: 8px;
}
.donor-list-item .donor-status.coming-soon {
  background: #d1a053;
}
.donor-list-item .donor-status.inactive {
  background: #bcbcc0;
  color: #1e1d1e;
}
.donor-list-item .donor-list-item__details {
  flex: 1;
  margin-left: 24px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details {
    margin: 0;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__top {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__top {
    justify-content: center;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__top h4 {
  color: #1b5291;
}
.donor-list-item .donor-list-item__details .donor-list-item__details__top .donor-favorite {
  margin-left: auto;
  border: 1px solid #7ea9db;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__top .donor-favorite {
    margin-left: 16px;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__top .donor-favorite:hover {
  cursor: pointer;
  border-color: #f46081;
}
.donor-list-item .donor-list-item__details .donor-list-item__details__mid {
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__mid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__mid h5 {
  margin-bottom: 4px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__mid h5 {
    text-align: center;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__mid span {
  margin: 0 16px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__mid span {
    font-size: 18px;
    margin: 8px 0;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__mid span:first-of-type {
  margin-left: 0;
}
.donor-list-item .donor-list-item__details .donor-list-item__details__mid span:last-of-type {
  margin-right: 0;
}
.donor-list-item .donor-list-item__details .donor-list-item__details__bottom {
  display: flex;
/* Bundle Status */
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__bottom {
    flex-direction: column;
    align-items: center;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__bottom .donor-list-item__charm {
  cursor: pointer;
  align-items: center;
  color: #1b5291;
  display: flex;
  font-weight: 800;
  margin-right: 24px;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__bottom .donor-list-item__charm {
    margin-bottom: 16px;
  }
}
.donor-list-item .donor-list-item__details .donor-list-item__details__bottom .donor-list-item__charm .tooltip > svg {
  margin-right: 8px;
}
.donor-list-item .donor-list-item__details .donor-list-item__details__bottom .donor-list-item__view-button {
  margin-left: auto;
  border: 2px solid #1b5291;
}
@media (max-width: 480px) {
  .donor-list-item .donor-list-item__details .donor-list-item__details__bottom .donor-list-item__view-button {
    margin: 0 auto;
    margin-top: 8px;
  }
}
.donor-traits {
  flex-basis: 250px;
  margin-right: 48px;
}
@media (max-width: 1024px) {
  .donor-traits {
    flex-basis: 400px;
  }
}
@media (max-width: 768px) {
  .donor-traits {
    flex-basis: 100%;
    margin-right: 0;
  }
}
.donor-traits h4 {
  align-items: center;
  color: #1b5291;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.donor-traits p {
  margin-bottom: 35px;
}
.donor-traits h6 {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-bottom: 2px solid #7ea9db;
  padding-bottom: 8px;
  margin-bottom: 24px;
}
.donor-traits h6 .toggle {
  cursor: pointer;
}
.donor-traits .trait-selector__clear-all {
  color: #1b5291;
  cursor: pointer;
}
.donor-traits .trait-selector__clear-all:hover {
  font-weight: 800;
}
.donor-traits .donor-traits__selectors h6 {
  cursor: pointer;
}
.donor-traits .donor-traits__selectors .toggleOptions {
  color: #1b5291;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 24px;
  cursor: pointer;
}
.donor-traits .donor-traits__selectors .toggleOptions:hover {
  text-decoration: underline;
}
.trait-selector {
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  margin-left: -12px;
  margin-right: -12px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
/* Trait Dropdown Value */
/* Trait Dropdown Label */
/* Trait Dropdown Menu */
}
.trait-selector:hover {
  background: rgba(126,169,219,0.25);
}
.trait-selector.trait-selector__ancestry > .trait-selector__options {
  bottom: 0;
  top: auto;
}
@media (max-width: 480px) {
  .trait-selector.trait-selector__ancestry > .trait-selector__options {
    top: 70px;
    bottom: auto;
  }
}
.trait-selector .link {
  color: #1b5291;
}
.trait-selector .link:hover {
  text-decoration: underline;
}
.trait-selector .trait-selector__value {
  font-size: 24px;
  line-height: 32px;
  color: #1b5291;
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  font-weight: 600;
  margin-bottom: 6px;
}
.trait-selector .trait-selector__value.trait-selector__value-small {
  font-size: 16px;
  line-height: 24px;
}
.trait-selector .trait-selector__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1e1d1e;
}
.trait-selector .trait-selector__options {
  position: absolute;
  z-index: 99;
  left: 200px;
  top: 0;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(148,122,178,0.3);
  border-radius: 12px;
  min-width: 280px;
/* Trait Dropdown Options */
}
@media (max-width: 480px) {
  .trait-selector .trait-selector__options {
    left: 0;
    top: 70px;
    width: 100%;
    max-height: 300px;
    overflow: auto;
  }
}
.trait-selector .trait-selector__options.trait-selector__height {
  min-width: 230px;
}
@media (max-width: 480px) {
  .trait-selector .trait-selector__options.trait-selector__height {
    max-height: inherit;
  }
}
.trait-selector .trait-selector__options .trait-selector__options-header {
  padding: 16px;
  border-bottom: 1px solid rgba(197,198,224,0.6);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}
.trait-selector .trait-selector__options .trait-selector__options-header .options-list--header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.trait-selector .trait-selector__options .trait-selector__options-header .options-list--header > * + * {
  margin-top: 8px;
}
.trait-selector .trait-selector__options .trait-selector__options-header .close-icon {
  cursor: pointer;
}
.trait-selector .trait-selector__options .trait-selector__options-header .trait-selector_header {
  font-weight: 800;
}
.trait-selector .trait-selector__options .trait-selector__options-header .trait-selector__search-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.trait-selector .trait-selector__options .trait-selector__options-header .trait-selector__search-wrapper .trait-selector__search {
  border: none;
  font-size: 16px;
  padding: 8px 16px;
  padding-left: 0;
  outline: 0;
  flex-grow: 1;
}
.trait-selector .trait-selector__options .trait-selector__options-header .trait-selector__search-wrapper .trait-selector__clear-all {
  display: inline-flex;
  margin-top: 8px;
}
.trait-selector .trait-selector__options .trait-selector__options-header .trait-selector__close {
  margin-top: 4px;
}
.trait-selector .trait-selector__options .trait-selector__option {
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(197,198,224,0.6);
  font-size: 18px;
  display: flex;
  align-items: center;
}
.trait-selector .trait-selector__options .trait-selector__option:last-of-type {
  border-bottom: none;
}
.trait-selector .trait-selector__options .trait-selector__option > svg {
  margin-right: 16px;
}
.trait-selector .trait-selector__options .trait-selector__option .trait-selector__charm {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.trait-selector .trait-selector__options .trait-selector__option .trait-selector__charm.banked:before {
  content: "\2746";
  font-size: 24px;
}
.trait-selector .trait-selector__options .trait-selector__option .trait-selector__charm.baby-bundle:before {
  content: "\1F6BC";
  font-size: 24px;
}
.trait-selector .trait-selector__options .trait-selector__option .trait-selector__charm.family-bundle:before {
  content: "\1F46A";
  font-size: 24px;
}
.trait-selector .trait-selector__options .trait-selector__option:hover {
  background: rgba(126,169,219,0.15);
}
.trait-selector .trait-selector__options .trait-selector__option.selected {
  font-weight: 800;
}
.trait-selector.trait-selector__ancestry .trait-selector__options {
  max-height: 404px;
  overflow-y: scroll;
}
.trait-selector-input--clear__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.trait-slider .trait-selector__range {
  padding: 35px 24px;
  height: 300px;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical {
  padding: 0 16px;
  width: 4px;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-track {
  width: 4px;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-thumb {
  width: 18px;
  height: 18px;
  margin-left: -7px;
  margin-bottom: -7px;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-thumb:hover,
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(27,82,145,0.16);
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail {
  width: 4px;
  background: #bcbcc0;
  opacity: 1;
  border-radius: 6px;
  position: relative;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail:before {
  content: '';
  position: absolute;
  background: #bcbcc0;
  width: 100%;
  height: 4px;
  top: -3px;
  left: -12px;
  padding: 0px 12px;
  border-radius: 6px;
}
.trait-slider .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail:after {
  content: '';
  position: absolute;
  background: #bcbcc0;
  width: 100%;
  height: 4px;
  bottom: -3px;
  left: -12px;
  padding: 0px 12px;
  border-radius: 6px;
}
.trait-slider .MuiSlider-thumbColorPrimary,
.trait-slider .MuiSlider-rail,
.trait-slider .MuiSlider-track {
  background-color: #1b5291;
}
.trait-slider .MuiSlider-markLabel {
  display: none;
}
.trait-slider .MuiSlider-valueLabel {
  left: 26px;
  top: 4px;
}
.trait-slider .MuiSlider-valueLabel span {
  background-color: #1b5291;
  border-radius: 6px;
  transform: none;
  min-width: 132px;
}
.trait-slider .MuiSlider-valueLabel span span {
  font-family: "Instrument Sans Variable", "Roboto Slab", "Georgia", "Times New Roman", sans-serif;
  text-align: center;
  transform: inherit;
  font-size: 14px;
  font-weight: 800;
}
.tooltip {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tooltip .tooltip__bubble {
  background: #fff;
  padding: 24px;
  position: absolute;
  min-width: 280px;
  left: -135px;
  z-index: 9000;
  border-radius: 11px;
  box-shadow: 0 0 15px 0 rgba(148,122,178,0.3);
}
.tooltip .tooltip__bubble.position--bottom {
  top: 48px;
}
.tooltip .tooltip__bubble.position--bottom:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  top: -19px;
  left: 48%;
}
.tooltip .tooltip__bubble.position--top {
  bottom: 48px;
}
.tooltip .tooltip__bubble.position--top:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  bottom: -19px;
  left: 48%;
}
.tooltip .tooltip__title {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 800;
  margin-bottom: 8px;
  color: #1e1d1e;
  font-size: 18px;
}
.tooltip .tooltip__title > svg {
  margin-right: 8px;
}
.tooltip .tooltip__content {
  font-size: 14px;
  line-height: 20px;
  color: #1e1d1e;
  font-weight: 300;
}
.input-scaffold {
  position: relative;
  display: block;
  width: 100%;
/* Specific input type styles */
/* Focus styles */
/* Disabled styles */
}
.input-scaffold .input {
  cursor: pointer;
}
.input-scaffold .input-rounded {
  border-radius: 40px;
}
.input-scaffold .input-label {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 8px;
}
.input-scaffold input,
.input-scaffold textarea,
.input-scaffold select {
  display: block;
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: 14px;
  border: 2px solid #bcbcc0;
  border-radius: 6px;
  padding: 16px;
  background: rgba(197,198,224,0.2);
  appearance: none;
}
.input-scaffold textarea {
  resize: vertical;
}
.input-scaffold select {
  background: none;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABYUlEQVRoge2YMU7EMBBF3yIuhFJyARo6aKHOCTYFHQU5AUegouMaEZehQ1pBgSMi5Dgz9hizYl4VxfbkP8lx7Oy6ruMYmKYpev/kl3OY4wKtcYHWuEBrXKA1LtAaF2hNjsCZeYqC2lqBHngFBu2DBAyhdq8ZpBHogcdw/YCtxBBqEp4hlpAKLMPPWEksw8+IJSQCO+Bipa1UIhZ+5lxSQCLwAVwDLyvtuRKp8E/AraSIdAq9A1ekJe6FtQh9U+FvgIOkkOYl3pK4A0ZBnTH0jaEKD/pldEtiT1piDH1iqMND3ocsV8I8PORvJbQSVcIDnOYMCswSz8BlpH2/cr2kKDyUCYBO4ifF4cFmN7o1nWKYhAe77bRGwiw82J4HJBKm4cH+QJOSMA8PdU5kMYkq4aF8FVpjuTq9USk81BOAb4kDlcJDXQH4kqjKv/wr8adwgda4QGtcoDUu0BoXaM3RC3wCkn5bVLDSEjUAAAAASUVORK5CYII=");
  background-size: 24px 24px;
  background-position: right 12px center;
  padding: 12px 48px 12px 16px;
  cursor: inherit;
}
.input-scaffold select::-ms-expand {
  display: none;
}
.input-scaffold input:focus,
.input-scaffold textarea:focus,
.input-scaffold select:focus {
  outline: none;
  border-color: #1b5291;
  box-shadow: 0px 0px 5px 0px rgba(27,82,145,0.35);
}
.input-scaffold .input--disabled,
.input-scaffold .input--disabled > * {
  cursor: not-allowed !important;
  opacity: 0.65;
}
.input-scaffold input[disabled],
.input-scaffold textarea[disabled],
.input-scaffold select[disabled] {
  background: #f8f8f9;
}
.input-scaffold__group {
  display: flex;
}
.input-scaffold__group > .input-scaffold {
  margin-right: 16px;
}
.input-scaffold__group > .input-scaffold:last-of-type {
  margin-right: 0;
}
.toggle-scaffold {
  position: relative;
/* Label */
/* Icon */
/* Icon ripple animation */
/* Focus styles */
/* Disabled styles */
}
.toggle-scaffold .toggle {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -12px;
}
.toggle-scaffold .toggle-label {
  font-size: 16px;
  font-weight: 600;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}
.toggle-scaffold .toggle-icon {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  padding: 12px;
  flex-shrink: 0;
}
.toggle-scaffold .toggle-icon svg {
  fill: #1e1d1e;
}
.toggle-scaffold .toggle-icon.toggle-icon--checked svg {
  fill: #1b5291;
}
.toggle-scaffold .toggle-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #1b5291;
  transform: scale(0, 0);
  opacity: 0.35;
  transition-property: transform, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0;
}
.toggle-scaffold .toggle-icon.toggle-icon--checked:after {
  transform: scale(1, 1);
  opacity: 0;
  transition-duration: 500ms;
}
.toggle-scaffold .toggle:focus {
  outline: none;
}
.toggle-scaffold .toggle:not(.toggle--disabled):focus .toggle-icon--checkbox svg {
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 3px rgba(27,82,145,0.5);
}
.toggle-scaffold .toggle:not(.toggle--disabled):focus .toggle-icon--radio svg {
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px rgba(27,82,145,0.5);
}
.toggle-scaffold .toggle--disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
}
.donors-list {
  display: flex;
  justify-content: center;
  max-width: 1370px;
}
@media (max-width: 768px) {
  .donors-list {
    flex-direction: column;
    width: 100%;
  }
}
.favorites-list {
  max-width: 1072px;
  flex: 1 1 100%;
}
@media (min-width: 1024px) {
  .favorites-list .previous-link {
    display: flex;
    justify-content: space-between;
  }
}
.donor-search {
  flex: 1 1 auto;
}
.donor-search__donor-list--grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 58px 40px;
}
@media (max-width: 1280px) {
  .donor-search__donor-list--grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1024px) {
  .donor-search__donor-list--grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .donor-search__donor-list--grid {
    grid-template-columns: 1fr;
  }
}
.donor-search__donor-list--list {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
}
@media (max-width: 480px) {
  .donor-search__donor-list--list {
    display: flex;
    flex-direction: column;
  }
}
.donor-search .donor-search__load-more {
  display: flex;
  justify-content: center;
  margin: 56px 0;
}
.donor-search .donor-search__load-more .button {
  background: rgba(197,198,224,0.25);
  border-radius: 50px;
  border: none;
  font-size: 14px;
  font-weight: 800;
  color: #1b5291;
  padding: 12px 20px;
}
.donor-search .donor-search__load-more .button:hover {
  background: rgba(197,198,224,0.5);
}
.donor-search__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 54px;
  align-items: center;
}
@media (max-width: 768px) {
  .donor-search__row {
    border-top: 1px solid rgba(197,198,224,0.6);
    border-bottom: 1px solid rgba(197,198,224,0.6);
    padding: 35px 0;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .donor-search__row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.donor-search__search-btn {
  max-width: 350px;
}
@media (max-width: 1024px) {
  .donor-search__search-btn {
    width: 100%;
    max-width: unset;
  }
}
@media (max-width: 768px) {
  .donor-search__search-btn {
    margin-bottom: 35px;
  }
}
.donor-search__search-btn .donor-search__form-container {
  flex: 1;
  position: relative;
  min-width: 350px;
}
@media (max-width: 480px) {
  .donor-search__search-btn .donor-search__form-container {
    min-width: auto;
  }
}
.donor-search__search-btn .donor-search__form-container > .input-scaffold {
  margin-bottom: 0 !important;
}
.donor-search__search-btn .donor-search__form-container > button {
  position: absolute;
  top: 8px;
  right: 9px;
  padding: 8px 20px;
  font-size: 14px;
}
.donor-search__search-btn .donor-search__form-container .donor-search__clear-btn {
  position: absolute;
  top: 18px;
  right: 124px;
  color: #1b5291;
  font-weight: 800;
}
.donor-search__search-btn form {
  display: flex;
}
.donor-no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  width: 100%;
  background: rgba(126,169,219,0.1);
}
.donor-no-results p {
  max-width: 350px;
  text-align: center;
}
.donor-search--header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.donor-search--header > * + * {
  margin-left: 24px;
}
@media (max-width: 1024px) {
  .donor-search--header {
    flex-direction: column;
  }
  .donor-search--header > * + * {
    margin-left: 0;
    margin-top: 24px;
  }
}
.donor-list--contact-us__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.donor-list--contact-us__wrapper > * + * {
  margin-left: 24px;
}
.donor-list--contact-us__wrapper .button {
  text-transform: none;
}
@media (max-width: 640px) {
  .donor-list--contact-us__wrapper {
    flex-direction: column;
  }
  .donor-list--contact-us__wrapper > * + * {
    margin-top: 24px;
    margin-left: 0;
  }
  .donor-list--contact-us__wrapper .button {
    width: 100%;
  }
}
.donor-profile-modal .modal__children {
  border-radius: 12px;
  max-width: 1280px;
  padding: 0;
/* Donor Contact Form */
}
@media (max-width: 1280px) {
  .donor-profile-modal .modal__children {
    max-width: 100%;
    margin: 64px 40px;
  }
}
@media (max-width: 480px) {
  .donor-profile-modal .modal__children {
    min-width: calc(100vw - 32px);
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
.donor-profile-modal .modal__children .donor-profile {
  display: flex;
  flex-direction: row;
  height: 100%;
/* Donor Profile Photos */
/* Donor Profile Right Content */
/* Donor Tabbed Content */
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile {
    flex-direction: column;
    height: auto;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container {
  width: 360px;
  background: #f1f1f8;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
/* Donor Profile Photos */
/* Donor Profile Notes */
/* Donor Profile Contact */
}
@media (max-width: 1060px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container {
    width: 271px;
  }
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container {
    width: 100%;
    position: relative;
    border-bottom-left-radius: 0;
    border-top-right-radius: 12px;
    overflow: hidden;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo {
  position: relative;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo {
    border-top-right-radius: 12px;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo > img {
  width: 100%;
  border-top-left-radius: 12px;
  image-orientation: none;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo > img {
    border-top-right-radius: 12px;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo .donor-favorite {
  align-items: center;
  background: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(197,198,224,0.6);
  position: absolute;
  z-index: 1000;
  right: 16px;
  top: 16px;
  height: 32px;
  width: 32px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo .donor-favorite:hover {
  cursor: pointer;
  border-color: #f46081;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo .button__view-gallery {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__profile-photo .button__view-gallery > svg {
  margin-right: 4px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__thumbnails {
  margin-top: -2px;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__thumbnails {
    display: flex;
    overflow-x: auto;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__thumbnails > img {
  cursor: pointer;
  image-orientation: none;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__notes {
  padding: 24px;
  border-top: 1px solid rgba(197,198,224,0.6);
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__notes .donor-profile__textarea {
  border: 1px solid rgba(197,198,224,0.6);
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  padding: 16px;
  font-size: 16px;
  margin-bottom: 8px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__notes .donor-profile__text-button {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__notes .donor-profile__text-button:hover {
  opacity: 0.7;
  text-decoration: none;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__contact {
  padding: 24px;
  text-align: center;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__contact {
    border-bottom: 1px solid rgba(197,198,224,0.6);
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__left-container .donor-profile__contact > p {
  width: 80%;
  font-size: 16px;
  line-height: 20px;
  margin: 0 auto;
  margin-bottom: 16px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container {
  flex: 1;
  padding-left: 360px;
  background: #fff;
  border-radius: 12px;
  overflow: auto;
/* Donor Profile Header */
/* Donor Profile Footer */
/* Donor Profile Navigation */
/* Donor Profile Content */
/* Donor Table */
/* Donor Horizontal Table */
/* Donor Vertical Table */
}
@media (max-width: 1060px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container {
    padding-left: 271px;
  }
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container {
    padding-left: 0;
    border-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__header {
  background: rgba(126,169,219,0.25);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__header h3 {
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  color: #1b5291;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__header .donor-profile__subheader {
  display: flex;
/* Donor Bundle */
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__header .donor-profile__subheader .charm {
  margin-top: 8px;
  background: #fff;
  border-radius: 50%;
  color: #1b5291;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(197,198,224,0.6);
  margin-right: 8px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__header .donor-profile__subheader .charm:last-of-type {
  margin-right: 0;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__footer {
  padding: 24px 0;
  margin: 0 24px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(197,198,224,0.6);
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__footer > svg {
  margin-right: 8px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav {
  border-bottom: 1px solid rgba(197,198,224,0.6);
  padding: 16px 8px;
  padding-bottom: 0;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav {
    padding: 16px;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul {
  display: flex;
  margin-bottom: -1px;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul {
    margin-bottom: 0;
    overflow-x: auto;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li {
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 800;
  color: #1e1d1e;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-top: 4px solid transparent;
  display: flex;
  align-items: center;
  opacity: 0.6;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li {
    border: none;
    border-radius: 50px;
    border: 2px solid #7ea9db;
    opacity: 1;
    padding: 16px 24px;
    margin-right: 8px;
    font-size: 18px;
    white-space: nowrap;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li > svg {
  margin-right: 8px;
  fill: #1e1d1e;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li > svg {
    fill: #1e1d1e;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li > svg > * > path {
  fill: #1e1d1e;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li > svg > * > path {
    fill: #1e1d1e;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active,
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li:hover {
  color: #1e1d1e;
  opacity: 1;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active {
  color: #1e1d1e;
  border: 1px solid rgba(197,198,224,0.6);
  border-top: 4px solid #1b5291;
  border-bottom: 1px solid #fff;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active {
    background: #1b5291;
    border: none;
    color: #fff;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active > svg {
  fill: #1b5291;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active > svg {
    fill: #fff;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active > svg > * > path {
  fill: #1b5291;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__navigation-tabs > nav ul li.active > svg > * > path {
    fill: #fff;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content {
  padding: 0 24px;
/* Donor Profile Field Group */
/* Specific Medical History Exclusion*/
}
@media (max-width: 1060px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content > div {
    overflow-x: auto;
  }
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content > div {
    overflow-x: auto;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content h5 {
  color: #1b5291;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content h5 > svg {
  margin-left: 4px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__content__expanded {
  border-bottom: 1px solid rgba(197,198,224,0.6);
  padding-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
}
@media (max-width: 768px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__content__expanded {
    flex-direction: column;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__field-group {
  flex: 1;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__field-group li {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__field-group li .donor-profile__field-label {
  font-weight: 800;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .donor-profile__content .donor-profile__medical-history .donor-profile__medical-history-section:last-of-type > .section-divider {
  display: none;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__filters {
  display: flex;
  margin-bottom: 16px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__filters > button {
  margin-right: 16px;
  font-size: 14px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__filters > button.active {
  font-weight: 800;
  color: #1e1d1e;
  text-decoration: none;
  cursor: default;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal {
  border-radius: 12px;
  margin-bottom: 24px;
  width: 100%;
  border-collapse: separate;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr:first-child > th:first-child {
  border-top-left-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr:first-child > th:last-child {
  border-top-right-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr:last-child > td:first-child {
  border-bottom-left-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr th {
  background: #1b5291;
  color: #fff;
  font-size: 16px;
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  font-weight: 800;
  padding: 16px;
  text-align: left;
  vertical-align: middle;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr td {
  font-size: 16px;
  line-height: 20px;
  color: #1e1d1e;
  border-bottom: 1px solid rgba(197,198,224,0.6);
  border-right: 1px solid rgba(197,198,224,0.6);
  border-left: 1px solid rgba(197,198,224,0.6);
  padding: 16px;
  vertical-align: middle;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__horizontal tr td:first-of-type {
  border-right: none;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical {
  border-radius: 12px;
  margin-bottom: 24px;
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
}
@media (max-width: 1280px) {
  .donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical {
    min-width: 600px;
  }
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr:first-child > th:first-child {
  border-top-left-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr:first-child > th:last-child {
  border-top-right-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr:last-child > th:first-child {
  border-bottom-left-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr th {
  background: #1b5291;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  border-bottom: 1px solid rgba(126,169,219,0.2);
  padding: 16px;
  text-align: left;
  vertical-align: middle;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr th.table__vertical__main-header {
  font-size: 16px;
  font-weight: 800;
  color: #1e1d1e;
  background: #fff !important;
  border-bottom: 1px solid rgba(197,198,224,0.6);
  border-top: 1px solid rgba(197,198,224,0.6);
  border-right: 1px solid rgba(197,198,224,0.6);
  text-align: center !important;
  text-transform: none;
  vertical-align: middle;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr th.table__vertical__main-header.small {
  font-size: 14px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__right-container .table__vertical tr td {
  font-size: 16px;
  line-height: 20px;
  color: #1e1d1e;
  border-bottom: 1px solid rgba(197,198,224,0.6);
  border-right: 1px solid rgba(197,198,224,0.6);
  padding: 16px;
  text-align: center;
  vertical-align: middle;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical {
  display: flex;
  margin-bottom: 24px;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical > nav {
  position: relative;
  z-index: 101;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical > nav ul li {
  padding: 12px 24px;
  border-left: 4px solid transparent;
  cursor: pointer;
  border-top: 1px solid rgba(197,198,224,0.6);
  border-right: 1px solid rgba(197,198,224,0.6);
  border-left: 1px solid rgba(197,198,224,0.6);
  font-size: 14px;
  color: #1b5291;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical > nav ul li.active {
  border-left: 4px solid #1b5291;
  border-right: 1px solid #fff;
  font-weight: 800;
  color: #1e1d1e;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical > nav ul li:last-of-type {
  border-bottom: 1px solid rgba(197,198,224,0.6);
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical .donor-profile__tabs-vertical__content {
  padding: 35px;
  border: 1px solid rgba(197,198,224,0.6);
  margin-left: -1px;
  flex: 1;
}
.donor-profile-modal .modal__children .donor-profile .donor-profile__tabs-vertical .donor-profile__tabs-vertical__content h5 {
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  margin-bottom: 8px;
}
.donor-profile-modal .modal__children .donor-profile__contact-form {
  padding: 35px;
}
.donor-profile-lightbox {
  background: #000;
  height: 100vh;
}
.schedule__card {
  border: 1px solid rgba(197,198,224,0.6);
  padding: 35px;
  max-width: 625px;
  position: relative;
  border-radius: 12px;
}
.schedule__card .deco-icon {
  opacity: 0.4;
  position: absolute;
  right: 24px;
  top: 24px;
}
.modal {
  display: none;
}
.modal.modal--visible {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal.lightbox .modal__overlay {
  background: rgba(0,0,0,0.8);
}
.modal.lightbox .modal__children {
  background: transparent;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
.modal.lightbox .lightbox__gallery {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 35px;
  height: calc(100vh - 125px);
}
.modal.lightbox .lightbox__gallery .lightbox__featured-image {
  text-align: center;
}
.modal.lightbox .lightbox__gallery .lightbox__featured-image > img {
  max-width: 100%;
  max-height: calc(100vh - 125px);
  image-orientation: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.modal.lightbox .lightbox__thumbnails {
  text-align: center;
}
.modal.lightbox .lightbox__thumbnails > img {
  width: 125px;
  cursor: pointer;
  image-orientation: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.modal.lightbox .lightbox__back-button {
  position: absolute;
  right: 16px;
  top: 16px;
}
.modal__children {
  position: relative;
  min-width: 500px;
  max-width: 600px;
  background: #fff;
  -webkit-overflow-scrolling: touch;
  z-index: 1002;
  border-radius: 12px;
  margin: 64px auto;
  padding: 35px;
}
@media (max-width: 480px) {
  .modal__children {
    min-width: calc(100vw - 32px);
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
.modal__children .modal__header {
  align-items: center;
  font-size: 24px;
  font-family: "Manrope Variable", "Muli", "Georgia", "Times New Roman", sans-serif;
  color: #1b5291;
  font-weight: 300;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(197,198,224,0.6);
}
.modal__children .modal__header > svg {
  margin-right: 8px;
}
.modal__children .modal__horizontal {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .modal__children .modal__horizontal {
    flex-direction: column;
  }
}
.modal__children .modal__horizontal > div {
  flex-basis: 48%;
}
@media (max-width: 480px) {
  .modal__children .modal__horizontal .modal__contact-form {
    border-top: 1px solid rgba(197,198,224,0.6);
    padding-top: 24px;
    margin-top: 24px;
  }
}
.modal__children .modal__content h4.subheader {
  font-size: 18px;
  margin-bottom: 8px;
  text-transform: none;
  display: inline-flex;
  align-items: center;
}
.modal__children .modal__content h4.subheader > svg {
  margin-right: 8px;
}
.modal__children .modal__content p {
  margin-bottom: 24px;
}
.modal__overlay {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0,0,0,0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border: none;
}
.modal__close {
  position: absolute;
  top: -48px;
  right: -8px;
  opacity: 0.8;
  cursor: pointer;
}
.modal__close:hover {
  opacity: 1;
}
.modal__back-arrow {
  position: absolute;
  top: -48px;
  left: -8px;
  opacity: 0.8;
  cursor: pointer;
}
.login-modal .modal__children {
  border-radius: 12px;
  max-height: inherit;
  width: auto;
  padding: 0;
  max-width: 850px;
}
.login-modal .modal__children .login-modal__wrapper {
  display: flex;
  height: 100%;
}
@media (max-width: 480px) {
  .login-modal .modal__children .login-modal__wrapper {
    flex-direction: column;
  }
}
.login-modal .modal__children .login-modal__donor-container {
  align-items: center;
  background: #1b5291;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 48px;
  width: 45%;
}
@media (max-width: 480px) {
  .login-modal .modal__children .login-modal__donor-container {
    width: 100%;
    border-bottom-left-radius: 0;
    border-top-right-radius: 12px;
    height: 300px;
    overflow: hidden;
  }
}
.login-modal .modal__children .login-modal__donor-container .login-modal__donor-image {
  position: relative;
  margin-bottom: 40px;
  min-width: 238px;
  min-height: 418px;
}
.login-modal .modal__children .login-modal__donor-container .login-modal__donor-image > img {
  border-radius: 12px;
  image-orientation: none;
}
.login-modal .modal__children .login-modal__donor-container .login-modal__donor-image:before {
  border-radius: 12px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.login-modal .modal__children .login-modal__donor-container .donor-card:before {
  background: none;
}
.login-modal .modal__children .login-modal__donor-container .login-modal__donor-id {
  align-items: center;
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
  background: #d1a053;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
}
.login-modal .modal__children .login-modal__content {
  padding: 40px;
}
